.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

[data-theme="dark"] .site-layout-content {
  background: #141414;
}

.pride {
  background-image: repeating-linear-gradient(
    #d04b36,
    #d04b36 10px,
    #e36511 20px,
    #ffba00 40px,
    #00b180 60px,
    #147aab 80px,
    #675997 100px,
    #d04b36 110px
  );
}

.pride .ant-input-affix-wrapper {
  border-right: #d04b36 5px solid !important;
  border-top: #00b180 solid 5px !important;
  border-bottom: #675997 solid 5px !important;
  border-left: #ffba00 solid 5px !important;
}

.pride .ant-input::placeholder {
  color: #d04b36 !important;
  font-weight: bold;
}

.pride .ant-table {
  background: rgba(256, 256, 256, 0.3);
}

.pride .ant-table-thead > tr > th {
  background: rgba(256, 256, 256, 0.5);
}

.pride .header {
  background-image: repeating-linear-gradient(
    #d04b36,
    #d04b36 14%,
    #e36511 28%,
    #ffba00 42%,
    #00b180 56%,
    #147aab 70%,
    #675997 84%,
    #d04b36 100%
  );
}

.numbers-table td {
  min-width: 60px;
}

.ant-form-item-with-help {
  margin-bottom: 8px;
}
