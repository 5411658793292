.customers-overview-table table {
  width: fit-content !important;
}

.mobile-view-cell {
  display: flex;
  flex-direction: column;
}

.mobile-view-cell h1 {
  font-size: 1.5em;
}

.mobile-view-cell h2 {
  font-size: 1em;
  margin-right: 0.5em;
}

.mobile-view-cell .normalizer-cell {
  margin-bottom: 1em;
}

.mobile-view-cell .normalizer-cell > div {
  display: flex;
  flex-direction: row;
}

.mobile-view-cell .pipelines-cell {
  display: flex;
  flex-direction: column;
}

.mobile-view-cell .pipelines-cell > div:not(:first-child) {
  margin-bottom: 1em;
}

.mobile-view-cell .more-info-cell {
  text-align: right;
}
