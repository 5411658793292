@import "~antd/dist/antd.css";

.menu {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  justify-content: space-evenly;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.menu-box {
  text-align: center;
}
